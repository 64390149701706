<template>
    <div class="backgroups" >
        <el-row>
            <el-header class="title">注册绑定</el-header>
        </el-row>
         <el-row>
<el-col :span="4">
    <div class="headerti">供应商:</div>
    
</el-col>
       <el-col :span="18">
    <el-input v-model="form.supplierName"></el-input>
       </el-col>
        </el-row>
        <el-row>
<el-col :span="4">
    <div class="headerti">姓名:</div>
    
</el-col>
       <el-col :span="18">
    <el-input v-model="form.name"></el-input>
       </el-col>
        </el-row>
<el-row>
<el-col :span="4">
    <div class="headerti">手机:</div>
    
</el-col>
 <el-col :span="18">
    <el-input v-model="form.mobile"></el-input>
       </el-col>
 </el-row>
 <el-row>
<el-col :span="4">
    <div class="headerti">邀请码:</div>
    
</el-col>
<el-col :span="18">
    <el-input v-model="form.code"></el-input>
       </el-col>
 </el-row>
<el-row type="flex"  justify="center">
<el-col :span="6"><el-button type="primary" @click="onSubmit">绑定</el-button></el-col>
<el-col :span="6"><el-button>取消</el-button></el-col>        
 </el-row>
 <!-- <el-footer ><div>网站备案号：<el-link href="https://beian.miit.gov.cn/" >闽ICP备18022481号-2</el-link></div></el-footer> -->
</div>
</template>
<script>
import  md5 from '../components/common/js/allSchool.js'
import {PostobjKeySort}  from '../components/common/js/printHelp.js'
import  sms from '../components/common/js/allSchool.js'
export default{

    data(){
        return{
            form:{
                name:"",
                mobile:"",
                code:"",
                unionid:"",
                ip:"",
                supplierName:""
            }
        }

        },
        mounted(){
           
  
            this.getOpenId()
         
        }
        ,methods:{
            //调用短信验证
            try_sms(){
                let url="https://service.winic.org/sys_port/gateway/index.asp"
                let parm={
                    id:"曾凌峰",
                    pwd:"th22285556",
                    to:this.mobile,
                    content:"你的验证码,[1234]",
                }

                this.$axios.post(
                    
                    url,
                parm,
               {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
            ).then(res=>{
                    console.log("res" ,res)
                })
            },

            getOpenId(){  
                let vars=this.$route.query.state.split("|")
                this.form.code=vars[0]
                this.form.supplierName=vars[1]
                if(this.$route.query.state!='')
                {
                    
            let url='http://api.thsen.top:15001/getip?cust_name=3333333333';
            let par={cust_name:3333333333}
            //this.reqM1Service('http://www.thsen.top:15001/getip',par,'get')
            this.$axios.get(url)
            .then(res => { 
                let ip=res.data.IP
                this.form.ip=res.data.IP
                let query={}
                

                  query= {
                     ip:ip,
                     code:this.$route.query.code,                   
                     isadmin:'false'              
                   }
                let newkey = PostobjKeySort(query)
                  
                   let sign= md5.M5(newkey.md5s)
                    let url="/SCM/GetUnionIDByCode?sign="
                   this.reqM1Service(url+sign,newkey.par,'post')
                   // this.$axios.post(url+sign,newkey.par)
                    .then((res=>{
                        console.log("绑定进入后",res)
                       this.form.unionid= res.data.unionid
                       this.form.name=res.data.name
                       this.form.mobile=res.data.mobile
                       
                    }))
                }) 
         
                }
                else //如果不是手动绑定方式进入
                {
                    
                    this.form.ip=this.$store.state.ip
                }
                
            },




            ///绑定openid 和code
            onSubmit(){

                 console.log("ip is ", this.$store.state.ip)

                    let query={
                        unionid:this.form.unionid,
                        verify:this.form.code,
                        mobile:this.form.mobile,
                        name:this.form.name,
                        ip:this.form.ip


                    }

                    let newkey = PostobjKeySort(query)                  
                   let sign= md5.M5(newkey.md5s)
                        this.reqM1Service("/SCM/BandInfo?sign="+sign,newkey.par,'post')
                //this.$axios.post("/SCM/BandInfo?sign="+sign,newkey.par)
                    .then((res => {
                        console.log(res)
                       if(res.data.code===200) {
                          this.$alert('<strong>绑定成功</strong>', '消息', {
                            dangerouslyUseHTMLString: true
                                });
                           
                       }
                       else{
                        this.$alert('<strong>绑定失败</strong>', '消息', {
                            dangerouslyUseHTMLString: true
                                  });
                       }
                       
                    })) 
                   
            }
        }
        }
</script>
<style>
.backgroups {
        position: relative;
        width: 100%;
        height: 100%;
  /*    background-image: url(../assets/img/login_back.jpg);*/
        background-size: 100%
    }
.title{
    margin-top: 30px;
    text-align: center;
    font-size: 30px;
}
.headerti{
    text-align: right;
    margin-right: 10px;
}
 .el-row {
    margin-bottom: 40px;
 }
       
  
</style>